import React, { lazy, Suspense, useState } from "react";
import { Link, graphql } from "gatsby";
import { Row, Col, Layout, Affix, Button, Spin } from "antd";
import PublicLayout from "../components/PublicLayout";
import SEO from "../components/seo";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { handlers } from "../helpers/handlers";
import OurHighlights from "./components/home/OurHighlights";
import BannerForm from "./components/home/BannerForm";

const { Content } = Layout;

const renderLoader = () => <Spin className='cmt-spin' />;
const JobAreas = lazy(() => import("./components/home/JobAreas"));
const OurClients = lazy(() => import('./components/home/OurClients'));
const CompaniesLoyalty = lazy(() => import("./components/home/CompaniesLoyalty"));
const OurStatistics = lazy(() => import("./components/home/OurStatistics"));
const OurCertifications = lazy(() => import('./components/home/OurCertifications'));
const BranchOffices = lazy(() => import("./components/home/BranchOffices"));
const FAQs = lazy(() => import("./components/home/FAQs"));

const Home = (props) => {
  const {
    data: {
      wpgraphql: {
        branchoffices,
        settings,
        jobareas,
        faqs,
      },
      allFile: {
        edges,
      }
    },
  } = props
  const { t } = useTranslation();
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;
  const [schemaExtraData, setSchemaExtraData] = useState([
    {
      "@type": "Organization",
      "logo": "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
      "sameAs": [
        "https://www.facebook.com/CentroMedicoDelTrabajador",
        "https://www.linkedin.com/company/centro-medico-del-trabajador",
      ],
    },
  ]);


  const handleRenderComponents = (component) => {
    if (typeof window !== 'undefined') {
      return (
        <Suspense fallback={renderLoader()}>
          {component}
        </Suspense>
      );
    }
  };

  return (
    <PublicLayout>
      <Layout className={`home-layout`}>
        <SEO
          title={t('seo.home.title')}
          description={t('seo.home.description')}
          dataLayer={{
            userId: "",
            pageId: "",
            pageType: "",
            category: "",
          }}
          schemaExtraData={schemaExtraData}
        />
        <Content>


          <BannerForm
            t={t}
            settings={settings}
            countryCode={countryCode}
          />

          <OurHighlights countryCode={countryCode} t={t} />

          {
            handleRenderComponents(<JobAreas t={t} jobAreas={jobareas} />)
          }

          {
            handleRenderComponents(
              <OurClients t={t} countryCode={countryCode} setSchemaExtraData={setSchemaExtraData} />
            )
          }

          {
            handleRenderComponents(<CompaniesLoyalty t={t} imagesLogos={edges} />)
          }

          {
            handleRenderComponents(<OurStatistics t={t} usedFrom='home' />)
          }

          {
            handleRenderComponents(<OurCertifications countryCode={countryCode} />)
          }

          {
            handleRenderComponents(
              <BranchOffices
                t={t}
                handlers={handlers}
                settings={settings}
                countryCode={countryCode}
                branchoffices={branchoffices}
              />
            )
          }

          {
            handleRenderComponents(
              <FAQs faqs={faqs} setSchemaExtraData={setSchemaExtraData} />
            )
          }

          <Affix
            className='floating-affix-desktop'
            offsetBottom={30}
            style={{ position: "absolute", right: '4vw' }}
          >
            <Link
              to="/contacto/"
              style={{ width: "80%" }}
              aria-label="Botón flotante de contacto"
            >
              <Button
                className='cmt-button primary floating'
                type="primary"
                aria-label="Botón flotante de contacto"
              >
                <Row align="middle" gutter={5}>
                  <Col>
                    <span className="icon-system-mail" />
                  </Col>
                  <Col>
                    ¡Contáctanos!
                  </Col>
                </Row>
              </Button>
            </Link>
          </Affix>

          <Affix
            className="floating-affix-mobile"
            offsetBottom={40}
            style={{ position: "absolute", right: '4vw' }}
          >
            <Link
              to="/contacto/"
              style={{ width: "100%" }}
              aria-label="Botón flotante de contacto"
            >
              <Button
                className="cmt-button primary floating"
                type="primary"
                aria-label="Botón flotante de contacto"
              >
                <span className="icon-system-mail" />
              </Button>
            </Link>
          </Affix>

        </Content>
      </Layout>
    </PublicLayout>
  )
}

export default Home

export const postQuery = graphql`
  query GET_HOME_CONTENT($country: String, $language: String!) {
    wpgraphql {
      branchoffices(where: { orderby: { field: MENU_ORDER, order: ASC }, country: $country }, first: 20) {
        nodes {
          fields {
            name
            address
            instructions
            mapUrl
            country
          }
        }
      }
      settings(where: { country: $country }) {
        nodes {
          fields {
            bannerImageOne {
              sourceUrl
              title
              altText
            }
            bannerImageTwo {
              sourceUrl
              title
              altText
            }
            bannerImageThree {
              sourceUrl
              title
              altText
            }
            centralPhone
          }
        }
      }
      jobareas(where: {country: $country, orderby: {field: MENU_ORDER, order: ASC}}, first: 15) {
        nodes {
          fields {
            country
            fieldGroupName
            lg
            name
            slug
            image {
              altText
              sourceUrl
              title
            }
          }
        }
      }
      faqs(where: {country: $country}) {
        nodes {
          fields {
            faq1Title
            faq1Content
            faq2Title
            faq2Content
            faq3Title
            faq3Content
            faq4Title
            faq4Content
            faq5Title
            faq5Content
          }
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile(
      filter: { sourceInstanceName: { eq: "logos" } }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

